export default [
    {
        component: 'CNavItem',
        name: 'Panel',
        access: 'panel',
        to: '/panel',
        icon: 'fas fa-check',
    },
    {
        component: 'CNavGroup',
        name: 'Mis tareas',
        access: 'tareas/mis-tareas-asignadas',
        to: '',
        icon: 'far fa-bell',
        items: [
            {
                component: 'CNavItem',
                name: 'Listado de mis tareas',
                access: 'tareas/mis-tareas-asignadas',
                to: '/admin/tareas/asignadas',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Tareas',
        access: 'Tareas',
        to: '',
        icon: 'fas fa-tasks',
        items: [
            {
                component: 'CNavItem',
                name: 'Listado de tareas',
                access: 'tareas/mis-tareas',
                to: '/admin/tareas',
            },
            {
                component: 'CNavItem',
                name: 'Operación por lote',
                access: 'tareas/mis-tareas/lote',
                to: '/admin/tareas/lote',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Administración',
        access: 'Administración',
        to: '',
        icon: 'fas fa-th-large',
        items: [
            {
                component: 'CNavItem',
                name: 'Flujos',
                access: 'admin/flujos',
                to: '/admin/flujos',
            },
            {
                component: 'CNavItem',
                name: 'Flujos Activar/Desactivar',
                access: 'admin/mis-flujos/activar',
                to: '/admin/mis-flujos/activar',
            },
            {
                component: 'CNavItem',
                name: 'Plantillas PDF',
                access: 'admin/plantillas-pdf',
                to: '/admin/plantillas-pdf',
            },
            {
                component: 'CNavItem',
                name: 'Variables de sistema',
                access: 'admin/system-vars',
                to: '/admin/system-vars',
            },
            {
                component: 'CNavItem',
                name: 'Archivadores',
                access: 'admin/archivadores',
                to: '/admin/archivadores',
            },
            {
                component: 'CNavItem',
                name: 'Orden de ventana por lotes',
                access: 'admin/orden_ventana_lote',
                to: '/admin/ventana-lote',
            },
            {
                component: 'CNavItem',
                name: 'OCR',
                access: 'admin/ocr_config',
                to: '/admin/ocr_config',
            },
        ],
    },
    /*{
        component: 'CNavGroup',
        name: 'Páginas',
        access: 'Páginas',
        to: '',
        icon: 'fas fa-newspaper',
        items: [
            {
                component: 'CNavItem',
                name: 'Listado de noticias',
                access: 'paginas/ver/noti',
                to: '/admin/blog/list',
            },
            {
                component: 'CNavItem',
                name: 'Listado de ayuda',
                access: 'paginas/ver/ayuda',
                to: '/admin/ayuda/list',
            },
        ],
    },*/
    {
        component: 'CNavGroup',
        name: 'Usuarios',
        to: '',
        icon: 'fas fa-user',
        access: 'Usuarios',
        items: [
            {
                component: 'CNavItem',
                access: 'users/admin',
                name: 'Administrar usuarios',
                to: '/usuarios/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/admin/grupos',
                name: 'Canales de usuarios',
                to: '/usuarios/canal/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/admin/grupos',
                name: 'Grupos de usuarios',
                to: '/usuarios/grupo/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/role/admin',
                name: 'Administrar roles',
                to: '/usuarios/roles/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/jerarquia/admin',
                name: 'Jerarquía de acceso',
                to: '/usuarios/jerarquia/listado',
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Reportes',
        access: 'reportes',
        to: '',
        icon: 'fas fa-file-download',
        items: [
            {
                component: 'CNavItem',
                name: 'Generar reporte',
                access: 'reportes/generar',
                to: '/reportes/generar',
            },
            {
                component: 'CNavItem',
                name: 'Configuración',
                access: 'reportes/admin',
                to: '/reportes/configuracion',
            },
        ],
    },
    /*{
        component: 'CNavGroup',
        name: 'Configuración',
        access: 'Configuración',
        to: '',
        icon: 'fas fa-cog',
        items: [
            {
                component: 'CNavItem',
                access: 'configuration',
                name: 'Administrar',
                to: '/configuration/sistema',
            },
        ],
    },*/
]
