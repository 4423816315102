<template>
    <ul class="sidebar-nav">
        <li class="nav-item show" v-for="item in menu" v-show="typeof access[item.access] !== 'undefined'">
            <a :class="{'nav-link nav-group-toggle': (typeof item.items !== 'undefined'), 'nav-link': (typeof item.items === 'undefined')}" @click="handleClick(item)">
                <span class="me-3">
                    <i v-if="item.icon ==='far fa-bell'" class="far fa-bell fa-lg position-relative">
                        <span :class="`badge rounded-circle bg-${typeTask[expire]} position-absolute start-90 translate-middle`" style="font-size: 0.75rem; top: -10px">
                        {{ task }}
                        </span>
                    </i>
                    <i v-else :class="item.icon"></i>
                </span>
                {{item.name}}
            </a>
            <ul class="nav-group-items" style="height: auto;" v-show="item.active">
                <li class="nav-item" v-for="child in item.items" v-show="typeof access[child.access] !== 'undefined'">
                    <a class="nav-link" aria-current="page" @click="handleClick(child)">{{child.name}}</a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
import {computed} from 'vue'
import {mapGetters, useStore} from 'vuex'
import logoNegative from '@/assets/images/logo.png'
import {sygnet} from '@/assets/brand/sygnet'
import navMenu from '/src/_nav'
import toolbox from "@/toolbox"

export default {
    name: 'AppSidebar',
    components: {},
    data() {
        return {
            menu: [],
            access: {},
            typeTask: ['warning','danger'],
            task: 0,
            expire: 0,
        };
    },
    computed: {
        ...mapGetters({
            loading: 'loading',
            authInfo: 'authInfo',
        })
    },
    watch: {
        authInfo(data) {
            console.log(data);
        }
    },
    mounted() {
        this.menu = navMenu;

        if (typeof this.authInfo.m !== 'undefined') {
            this.access = this.authInfo.m;
        }
        this.getCountTasks();
    },
    methods: {
        handleClick(item) {
            const self = this;
            if(item.to) {
                if (self.$route.path !== item.to) self.$router.push(item.to)
                else self.reloadCurrentRoute()
            }
            else item.active = (!item.active);
        },
        reloadCurrentRoute() {
            const self = this;
            const currentRoute = self.$route.path;
            self.$router.replace({ path: '/admin' }).then(() => {
                self.$router.replace({ path: currentRoute });
            });
        },
        getCountTasks() {
            const self = this;
            toolbox.doAjax('POST', 'tareas/count-my-tasks', {
                },
                function (response) {
                    self.task = response.data.count;
                    self.expire = response.data.expire? 1 : 0;
                },
                function (response) {
                    // toolbox.alert(response.msg, 'danger');
                })

        }
},
}
</script>
