<template>
    <div class="nav-item dropdownHeaderCustom">
        <a class="nav-link dropdown-toggle py-0 pe-3 pt-1" @click="showTask = !showTask">
            <i class="fas fa-list-dots fa-lg"></i>
        </a>
        <div class="dropdownHeaderCustomList" v-if="showTask">
            <div class="text-muted mb-2">
                Tareas por flujo
            </div>
            <div class="row">
                <div class="col-12" v-for="item in conteo">
                    <div type="button" class="btn btn-light w-100 mb-1" @click="filterTareas(item.pid)">
                        <div style="font-size: 12px" class="float-start">
                            {{item.p}}
                        </div>
                        <span class="float-end badge text-bg-primary">{{item.c}}</span>
                    </div>
                </div>
            </div>
            <div class="text-muted mb-2 mt-3">
                Tareas recientes
            </div>
            <div v-for="item in items" class="cursor-pointer dropdownHeaderCustomItem" @click="goTask(item)">
                <a :href="'/#/solicitar/producto/'+item.productoTk+'/'+item.token" class="text-dark text-decoration-none">
                    <div>
                        <i class="fas fa-tasks me-2"></i><b>Tarea No.{{item.id}} - {{item.producto}}</b>
                    </div>
                    <div>
                        Estado: <span style="text-transform: capitalize">{{item.estado}}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <a class="nav-item py-0 pe-3" :href="link" target="_blank">
        <div class="btn btn-light btn-sm"><i class="fas fa-info-circle fa-lg me-2"></i>Soporte</div>
    </a>
</template>

<script>
import avatar from '@/assets/images/avatars/1.png'
import {mapMutations} from "vuex";
import {logo} from "@/assets/brand/logo";
import toolbox from "@/toolbox";

export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
        return {
            avatar: avatar,
            itemsCount: 42,
        }
    },
    data() {
        return {
            conteo: {},
            items: {},
            showTask: false,
            link: '',
        };
    },
    mounted() {
        this.getTareas();
        //document.getElementsByClassName('userMenu').removeAttribute('href');
    },
    methods: {
        getTareas() {
            const self = this;
            toolbox.doAjax('POST', 'tareas/get-fast-view', {
                },
                function (response) {
                    self.items = response.data.c;
                    self.link = response.data.l.contenido;
                    self.conteo = response.data.pc;
                },
                function (response) {
                    toolbox.alert(response.msg, 'danger');
                })
        },
        filterTareas(pr) {
            this.$router.push('/admin/tareas/' + pr);
        },
        goTask(item) {
            /*this.$router.push('/#/solicitar/producto/'+item.productoTk+'/'+item.token);
            setTimeout(function () {
                location.reload();
            }, 800);*/

        }
    }
}
</script>
